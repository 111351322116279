.project-detail {
  .goback {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    margin-top: 15px;
    z-index: 9;
    svg {
      font-size: 40px;
    }
  }
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-size: 1.5rem;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .projectLink {
    a {
      margin-left: 5px;
      background: linear-gradient(
        90deg,
        rgba(244, 4, 4, 1) 0%,
        rgba(13, 13, 177, 1) 35%,
        rgba(6, 77, 215, 1) 50%,
        rgba(0, 86, 255, 1) 100%
      );
      background-size: 200% 200%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block; /* Necessary for gradients to work on text */
      animation: gradientAnimation 2s ease infinite;
    }
  }
  .process {
    span {
      text-transform: uppercase;
      font-weight: bold;
      color: red;
    }
  }
  .projectImage {
    margin-top: 10px;
    img {
      border-radius: 20px;
    }
    .btn {
      font-size: 20px;
      background: linear-gradient(
        90deg,
        rgba(244, 4, 4, 1) 0%,
        rgba(13, 13, 177, 1) 35%,
        rgba(6, 77, 215, 1) 50%,
        rgba(0, 86, 255, 1) 100%
      );
      background-size: 200% 200%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block; /* Necessary for gradients to work on text */
      animation: gradientAnimation 2s ease infinite;
    }
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
