.navigation {
  width: 503px;
  height: 100px;
  border-radius: 20px;
  background: #f2f7fc;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  float: right;
  margin-top: 42px;
  margin-bottom: 10px;
  .nav_card {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    a {
      margin: 10px 0;
      width: 80px;
      height: 80px;
      padding: 20px;
      border-radius: 20px;
      background: #e1e8ef;
      svg {
        width: 24px;
        height: 24px;
      }
      p {
        color: #000;
        font-family: "Raleway";
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      &.active {
        background: linear-gradient(91deg, #ff9c1a 7.92%, #e80505 108.2%);
        svg,
        p {
          color: white;
        }
      }
    }
  }
}
