.header {
  .header_wrapper {
    display: flex;

    height: 104px;
    padding: 44px 0px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    .logo {
      font-family: "Pacifico";
      font-size: 1.5rem;
      span {
        background: linear-gradient(180deg, #ff9c1a 0%, #ed250a 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .mode {
      border: none;
      cursor: pointer;
      background-color: #ebf2fa;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      display: flex;
      width: 56px;
      height: 56px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.night-mode {
  background-color: black;
  .header {
    .logo {
      p {
        color: white;
      }
    }
  }
  .navigation {
    background-color: rgb(44, 43, 43);
    .arrow-button {
      color: white;
    }
  }

  .profile {
    background-color: rgb(44, 43, 43);
    h1,
    p {
      color: white;
    }
    .info {
      background-color: transparent;
      p {
        color: red !important;
      }
      a {
        color: white !important;
      }
    }
  }
  .home,
  .resume,
  .work,
  .contact {
    background-color: rgb(44, 43, 43);
    h1,
    h2,
    .title,
    .introduce,
    label,
    button {
      color: white !important;
    }
    .skill-title {
      h2 {
        color: black !important;
      }
    }
    .contact-form {
      p {
        color: white !important;
      }
      button[type="submit"]:disabled {
        color: black !important;
      }
    }
  }
}
