.contact {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  h1 {
    letter-spacing: 1px;
    color: #0b0909;
    font-size: 2.5rem;
    font-weight: 500;
    font-weight: bold;
    display: inline-block;
  }
  .phone,
  .email {
    border-radius: 20px;
    background: #ffeed9;
    height: 150px;
    .content {
      padding: 30px;
    }
    a {
      display: block;
      margin: 10px 0;
      font-size: 20px;
    }
  }
  .contact-form {
    p {
      font-size: 30px;
      text-align: center;
      font-family: "Poppins";
    }
    form {
      label {
        display: block;
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0;
      }
      input {
        width: 70%;
        height: 40px;
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        border-bottom: 2px solid #b5b5b5;
      }
      textarea {
        resize: none;
        width: 70%;
        height: 100px;
      }
      button[type="submit"] {
        color: #000;
        cursor: pointer;
        margin-top: 20px;
        font-family: "Poppins";
        display: block;
        width: 150px;
        font-size: 30px;
        border-radius: 20px;
        background-color: transparent;
        border: 1px solid #ff6651;
        &:disabled {
          color: #b5b5b5;
          border: 1px solid transparent;
          cursor: not-allowed;
        }
      }
    }
  }
}
