.work {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  img.no-image {
    display: none;
  }
  h1 {
    letter-spacing: 1px;
    color: #0b0909;
    font-size: 2.5rem;
    font-weight: bold;
  }
  .projectCard {
    border-radius: 8px;
    background: #ffe3bf;
    width: 350px;
    margin-bottom: 40px;
    margin-right: 24px;
    padding: 8px;
    img {
      width: 334px;
      height: 100px;
    }
    .projectName {
      font-weight: 700;
      font-size: 20px;
      text-transform: capitalize;
    }
    .techused {
      color: rgba(26, 16, 3, 0.5);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .coming-soon {
    pointer-events: none;
    cursor: not-allowed;
    position: relative;
    background-color: #f7f7f7;
    color: #999;
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    &::before {
      position: absolute;
      content: "Coming Soon...";
      transform: rotate(20deg); /* slightly tilted */
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      top: 30%;
      left: 25%;
      color: #f00202;
      background-color: rgba(26, 16, 3, 0.4);
    }
  }
}
