
.resume {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  h1 {
    letter-spacing: 1px;
    color: #0b0909;
    font-size: 2.5rem;

    font-weight: bold;
  }
  .education_title,
  .experience_title {
    .icon {
      display: inline-block;
      width: 28px;
      height: 28px;
    }
    .title {
      display: inline-block;
      color: #000;
      margin-left: 10px;
      font-family: "Poppins";
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: 0.7px;
    }
  }
  .card {
    margin-bottom: 25px;
    padding: 15px 0;
    font-size: 1rem;
    font-family: "Raleway";
    border-radius: 10px;
    width: 100%;
    height: 116px;
    background: #ffe3bf;
    h2 {
      margin-left: 30px;
      color: black !important;
    }
    .year,
    .subject,
    .school {
      margin: 10px;
    }
    .year {
      color: rgba(26, 16, 3, 0.5);
      font-weight: 400;
    }
    .subject {
      color: #000;
      font-weight: 600;
      text-transform: capitalize;
    }
    .school {
      color: #000;
      font-weight: 500;
    }
  }

  .skills {
    background-color: #f8fbfb;
    grid-row: 2;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    .skill-title {
      color: #000;
      font-family: "Poppins";
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: 0.7px;
      margin: 20px 0;
    }
    .skill-card {
      width: 80%;
      .skill-item {
        margin-bottom: 20px;
        display: inline-block;
        background-color: #e1e8ef;
        margin-right: 35px;
        display: inline-block;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        .skill-name {
          text-transform: capitalize;
        }
      }
    }
  }
}
