@import "./color";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
ul {
  list-style: none;
}
body {
  font-size: 16px;
  background-color: $white_color;
  scroll-behavior: smooth;
  h1 {
    font-family: "Poppins";
    text-transform: uppercase;
  }
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Pacifico";
  src: url("../fonts/Pacifico/Pacifico-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/static/Raleway-Regular.ttf");
}
.lines {
  svg {
    width: 252px;
    height: 2px;
    stroke-width: 2px;
    stroke: #fe9119;
    display: inline-block;
  }
}
